const STATUS_LIST = [
    {
        id: '1',
        title: '待我审批',
        hasMore: true,
        icon: 'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/pending-my-approval.png',
        vHasPermi: ['waitMe:all'],
        nums: 0
    },
    {
        id: '2',
        title: '我已审批',
        hasMore: true,
        icon: 'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/already-approved-it.png',
        vHasPermi: ['doneMe:all']
    },
    {
        id: '4',
        title: '我发起的',
        hasMore: true,
        icon: 'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/i-initiated.png',
        vHasPermi: ['applyMe:all']
    },
    {
        id: '3',
        title: '抄送我的',
        hasMore: true,
        icon: 'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/copy-me.png',
        vHasPermi: ['ccMe:all']
    }
];

const randomizationImg = [
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-1.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-2.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-3.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-4.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-5.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-6.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-7.png',
    'https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/approved/bj-8.png'
];

export {
    STATUS_LIST,
    randomizationImg
}