export default {
    "processList": [
        {
            text: '1、表单设计',
            sel: true,
        },
        {
            text: '2、配置信息',
            sel: false,
        }
    ],
    // 基础信息填写
    'basicInformationFilling': {
        'ruleForm': {
            iconName: '', // 图标名称
            formName: '', // 表单名称
            groupId: '', // 所在分组
            userPopulation: '1', // 适用人群1教职工2学生3不限制
            initiate: '', // 谁可以发起
            personInfo: [], // 人员详情人员ID数组
            organInfo: [], // 机构详情机构id数组
            remark: '', // 表单说明

        },
        'rules': {
            formName: [
                { required: true, message: '请输入表单名称', trigger: 'blur' },
                { max: 30, message: '长度在30个字符内', trigger: 'blur' }
            ],
            groupId: [
                { required: true, message: '请选择所在分组', trigger: 'change'}
            ],
            userPopulation: [
                { required: true, message: '请选择适用人群', trigger: 'change' }
            ],
            initiate: [
                {required: true, message: '请选择谁可以发起', trigger: 'change'}
            ]
        }
    },
    // 提交数据
    'submitData': {
        id: '',
        iconName: '',
        schoolId: '',
        formName: '',
        groupId: '',
        userPopulation: '',
        personInfo: [],
        organInfo: [],
        fromInfo: {
            list: []
        },
        dataTitle: '##fieldName1##提交的##fieldName2##',
        dataAbstract: {},
        _dataAbstract: '',
        allowCancle: '',
        allowEdit: '',
        allowAbsence: '',
        releaseStatus: '',
        schoolProcessDesignList: [],
        schoolProcessDesignInfo: {},
        remark: ''
    }
}