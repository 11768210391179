/*
    套件-换班
*/
export let kitsShiftChange = {
    id: '',
    _id: '',
    // 是否可拖拽
    draggable: true,
    key: 'kitsShiftChange',
    compType: 'kitsShiftChange',
    ele: 'kits-shift-change',
    compName: '换班套件', //控件名称(左侧显示)
    viewType: 'text', //展示表单的模式
    options: '1', // 选项
    selectableRange: '1', // 换班人可选范围
    config: true, // 是否可配置
    showLabel:true,
    label: '',
    labelWidth: '0',
    placeholder: '',
    maxLength: 100, // 换班原因最大长度
    gutter: 15, //栅格间隔
    span: 24, //默认栅格
    required: true, // 是否必填
    width: '95%', //组件长度
    status:'normal',
    value: '',
    hideStatus: true,
    kitLibraryStatus: true,
    usageScenarios: 1, // 使用场景 1查寝换班 2其他
    shiftWorkerAudit: 1 // 换班人审核 1是 0否
}
