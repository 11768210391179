
/*
    学生评价班主任
*/
export let EvaluateTeachersBan = {
    id:'',
    _id:'',
    // 是否可拖拽
    draggable: true,
    key: 'EvaluateTeachersBan',
    compType: 'EvaluateTeachersBan',
    ele: 'evaluate-teachers-ban',
    //控件名称(左侧显示)
    compName:'学生评价班主任',
    //展示表单的模式
    viewType:'text',
    // 选项
    options: '1',
    // 可选范围
    selectableRange: '1',
    // 是否可配置
    config: true,
    showLabel:true,
    label: '',
    labelWidth: '0',
    placeholder: '',
    // 最大长度
    maxLength: 50,
    //栅格间隔
    gutter:15,
    //默认栅格
    span:24,
    // 是否必填
    required: true,
    //组件长度
    width: '100%',
    status:'normal',
    value: '',
    hideStatus: true,
    kitLibraryStatus: true,
    fixedTime:{
        'on-off': 0,
        'timeSetting':[{time: ''}]
    },
    // 说明
    description: '根据学生所在班级，自动匹配对应班主任评价信息',
    selecItem: "", //选中项
    children: [
        {
            label: '学生评价班主任',
            labelWidth: 300,
            placeholder: '',
            // 最大长度
            maxLength: 50,
            //栅格间隔
            gutter: 15,
            //默认栅格
            span: 24,
            // 是否必填
            required: true,
            //组件长度
            width: '95%',
            status: 'normal',
            value: '',
            num: 5,
            options:[
                {
                    label: '非常好',
                    value: 100,
                },
                {
                    label: '比较好',
                    value: 80
                },
                {
                    label: '一般',
                    value: 60
                },
                {
                    label: '比较差',
                    value: 40
                },
                {
                    label: '非常差',
                    value: 20
                },
            ]
        }
    ]
  }



