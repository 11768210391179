/*
    说明文字
*/ 

export let descriptionText = {
    id:'',
    _id:'',
    compType: 'descriptionText',
    ele: 'el-input',
    //控件名称(左侧显示)
    compName:'说明文字',
    //图标
    compIcon:'input',
    //展示表单的模式
    viewType:'text',
    
    // 是否可配置
    //暂时默认为true，无法更改，后期考虑某些时候无法配置
    config: true,
    // 控件左侧label内容
    showLabel: false,
    label: '',
    labelWidth: '90',
    color: '#409EFF',
    fontSize: 12,
    type: 'textarea',
    //组件长度
    width: '95%',
    //状态，正常，只读，禁用
    status:'normal',
    value: '',
    /*
    *关联状态
    affiliateStatus/ 当删除时是否可以删除
    hideStatus/ 是否隐藏
    */
    affiliateStatus: true,
    hideStatus: true,

  }
  