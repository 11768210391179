let baseInfo = [
    {
        title: '基础信息',
        rules: 'basicInfo',
        text: '',
        finish: false,
        key: '#J_teachInfo_basic_info'
    },
    {
        title: '职务信息',
        rules: 'positionInfo',
        text: '',
        finish: false,
        key: '#J_teachInfo_position_info'
    },
    {
        title: '账号信息',
        rules: 'accountInfo',
        text: '',
        finish: false,
        key: '#J_teachInfo_account_info'
    },
    {
        title: '数据权限',
        rules: 'dataInfo',
        text: '',
        finish: false,
        key: '#J_teachInfo_data_info'
    }
],
moreInfo = [];

export {
    baseInfo,
    moreInfo
}
