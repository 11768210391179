let formRules = {
    basicInfo: ["teacherName", "phone", "onlyCode", "sex"],
    positionInfo: ["orgList", "isSubjectTeacher", "isHeadmaster"],
    accountInfo: ["loginName", "userName", "password"],
};

let changeRules = {
    defaultRule: ["orgList", "isSubjectTeacher", "isHeadmaster"],
    correlationTeachers: ["orgList", "isSubjectTeacher", "isHeadmaster", "subject"],
    correlationClassTeacher: ["orgList", "isSubjectTeacher", "isHeadmaster", "manageClassList"],
    allRules: ["orgList", "isSubjectTeacher", "isHeadmaster", "subject", "manageClassList"]
};

export {formRules, changeRules};
