/*
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-10-13 15:20:24
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-11-13 16:22:07
 * @FilePath: \cloud_campus_Front-end\school_end\src\assets\data\formDesign\comps\kitsSpecialLeave.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
    套件-请假/调休
*/
export let kitsSpecialLeave = {
    id:'',
    _id:'',
    // 是否可拖拽
    draggable: true,
    key: 'kitsSpecialLeave',
    compType: 'kitsSpecialLeave',
    ele: 'kits-special-leave',
    //控件名称(左侧显示)
    compName:'特殊请假套件',
    //展示表单的模式
    viewType:'text',
    // 选项
    options: '1',
    // 可选范围
    selectableRange: '1',
    // 是否可配置
    config: true,
    showLabel:true,
    label: '',
    labelWidth: '0',
    placeholder: '',
    // 最大长度
    maxLength: 50,
    //栅格间隔
    gutter:15,
    //默认栅格
    span:24,
    // 是否必填
    required: true,
    //组件长度
    width: '95%',
    status:'normal',
    value: '',
    hideStatus: true,
    kitLibraryStatus: true,
    isInSetting: 1, // 关联门禁 1是 0否
    reasonLeaveRequired: 0, // 请假事由是否为必填默认为0（非必填）
    showLimit: 0, // 请假时长限制 1是 0否
    limitDuration: 2, // 请假时长
  }

