// 基准宽高1920x960
export const standardWidth = 1920
export const standardHeight = 960
export const defaultTheme = 0 // 下标
export const themeList = [
    {
        id: "1",
        thumbnail: require('@/assets/images/loginPageTemplate/theme1/thumbnail.png'),
        background: {
            // default: require('@/assets/images/loginPageTemplate/theme1/background.png'),
            default: "https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/my/background1.png",
            type: 'picture',
            imageCandidates: [],
            images: [],
            fillMethod: 'cover',
            carouselSpeed: 2,
            video: '',
            color: '#FFF'
        },
        loginBox: {
            id: 'loginBox',
            type: "LoginBox",
            background: require('@/assets/images/loginPageTemplate/theme1/login_box_background.png'),
            top: 204,
            left: 1218,
            width: 478,
            height: 574,
            opacity: 1,
            zIndex: 1
        }
    },
    {
        id: "2",
        thumbnail: require('@/assets/images/loginPageTemplate/theme2/thumbnail.png'),
        background: {
            // default: require('@/assets/images/loginPageTemplate/theme2/background.png'),
            default: "https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/my/background2.png",
            type: 'picture',
            imageCandidates: [],
            images: [],
            fillMethod: 'cover',
            carouselSpeed: 2,
            video: '',
            color: '#FFF'
        },
        loginBox: {
            id: 'loginBox',
            type: "LoginBox",
            background: require('@/assets/images/loginPageTemplate/theme2/login_box_background.png'),
            top: 254,
            left: 1240,
            width: 478,
            height: 574,
            opacity: 1,
            zIndex: 1
        }
    },
    {
        id: "3",
        thumbnail: require('@/assets/images/loginPageTemplate/theme3/thumbnail.png'),
        background: {
            // default: require('@/assets/images/loginPageTemplate/theme3/background.png'),
            default: "https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/my/background3.png",
            type: 'picture',
            imageCandidates: [],
            images: [],
            fillMethod: 'cover',
            carouselSpeed: 2,
            video: '',
            color: '#FFF'
        },
        loginBox: {
            id: 'loginBox',
            type: "LoginBox",
            background: require('@/assets/images/loginPageTemplate/theme3/login_box_background.png'),
            top: 247,
            left: 699,
            width: 478,
            height: 574,
            opacity: 1,
            zIndex: 1
        }
    }
]
