/*
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2024-03-27 11:35:04
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2024-03-28 14:01:49
 * @FilePath: \cloud_campus_Front-end\school_end\src\assets\data\formDesign\comps\kitsDayStudent.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
    走读申请套件
*/
export let kitsDayStudent = {
    id:'',
    _id:'',
    // 是否可拖拽
    draggable: true,
    key: 'kitsDayStudent',
    compType: 'kitsDayStudent',
    ele: 'kits-day-student',
    //控件名称(左侧显示)
    compName:'走读申请套件',
    //展示表单的模式
    viewType:'text',
    // 选项
    options: '1',
    // 可选范围
    selectableRange: '2',
    fontColor:'#3C7FFF',
    fontSize:12,
    fontValue:'',
    // 原因是否必填
    reasonRequired:1,
    // 附件是否必须上传
    attachmentRequired:0,
    // 是否允许多个申请人
    isAllowMultipleApplicant:0,
    // 允许上传的文件类型
    accept:".jpg,.jpeg,.png,.gif,.bmp,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.txt,.zip,.rar,.7z",
    // 说明文字
    dayStudentComment:'审批通过后，在开始和结束时间内，申请人的学生类型会变为表单中的学生类型',
    // 是否可配置
    config: true,
    showLabel:true,
    label: '',
    labelWidth: '0',
    placeholder: '',
    // 最大长度
    maxLength: 200,
    //栅格间隔
    gutter:15,
    //默认栅格
    span:24,
    // 是否必填
    required: true,
    //组件长度
    width: '95%',
    status:'normal',
    value: '',
    hideStatus: true,
    kitLibraryStatus: true,
  }

