// 自定义组件
import { personnelInput } from "./comps/personnelInput";
import { multipleSelect } from './comps/multipleSelect';
import { singleLineText } from "./comps/singleLineText";
import { rate } from "./comps/rate";
import { multiLineText } from './comps/multiLineText';
import { radio } from "./comps/radio";
import { phoneNumber } from './comps/phoneNumber';
import { number } from './comps/number';
import { IDCard } from './comps/IDCard';
import { address } from './comps/address'
import { upload } from "./comps/upload2";
import { dateTime } from "./comps/dateTime";
import { descriptionText } from './comps/descriptionText';
import { attachmentUpload } from './comps/attachmentUpload'; // 附件上传

// 系统
import { evaluateTeachers } from './comps/evaluation'; // 学生评价老师
import { EvaluateTeachersBan } from './comps/evaluationBan'; // 学生评价班主任

// 套件库
import { kitsLeaveAbsence } from './comps/kitsLeaveAbsence'; // 请假/调休套件
import { kitsSpecialLeave } from './comps/kitsSpecialLeave'; // 特殊请假
import { kitsShiftChange } from './comps/kitsShiftChange'; // 换班套件
import { kitsDayStudent } from './comps/kitsDayStudent'; // 走读申请套件

export const commonComponents = [
  evaluateTeachers, EvaluateTeachersBan
];

export const formItems = [
  singleLineText,
  multipleSelect,
  multiLineText,
  radio,
  phoneNumber,
  number,
  IDCard,
  upload,
  dateTime,
  descriptionText,
  rate
];

export const componentLibraryData = [
  kitsLeaveAbsence,
  kitsShiftChange,
  kitsSpecialLeave,
  kitsDayStudent
]
