/**
 * 多行文本
 */
 export let multiLineText = {
    id:'',
    _id:'',
    compType: 'multiLineText',
    key: 'multiLineText',
    ele: 'el-input',
    //控件名称(左侧显示)
    compName:'多行文本',
    //图标
    compIcon:'input',
    //展示表单的模式
    viewType:'text',
  
    // 是否可配置
    //暂时默认为true，无法更改，后期考虑某些时候无法配置
    config: true,
    // 控件左侧label内容
    showLabel: true,
    label: '多行文本',
    labelWidth: '90',
    placeholder: '请输入文本',
    type: 'textarea',
    // 是否必填
    required: false,
    // 最大长度
    maxLength: 50,
    //栅格间隔
    gutter:15,
    //默认栅格
    span:24,
    //组件长度
    width: '95%',
    //状态，正常，只读，禁用
    status:'normal',
    value: '',
    /*
    *关联状态
    affiliateStatus/ 当删除时是否可以删除
    hideStatus/ 是否隐藏
    */
    affiliateStatus: true,
    hideStatus: true,
}
  